.req {
  flex: 1 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  font-size: 2em;  
  text-align: left;  
}

.req-header {  
  flex: 0 0 60px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 5px 20px 5px 20px;  
  line-height: 1;
}

.req-header.img {
  background: url("../images/logo_pictogram.png") left no-repeat;  
  background-size: 60px;
  background-position: 20px 50%;    
}

.req-header.orange {  
  color: white;
  background: #ff6c00;  
}

.req-header.orange.blink {   
  animation: blinkingBackground 3s infinite;
}

@keyframes blinkingBackground{
  0%		{ background-color: #ff6c00;}
  25%		{ background-color: #fff;}
  50%		{ background-color: #ff6c00;}
  75%		{ background-color: #fff;}
  100%  { background-color: #ff6c00;}
}

.req-subheader {
  flex: 0 0 auto;  
  padding: 20px;  
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.req-content {
  flex: 1 0 auto;
  padding: 20px;
  font-size: 0.8rem;  
}

.req-content .list {  
  display: flex;
  flex-flow: column nowrap;
}

.req-content .list .list-row {
  flex: 1 0 auto;
  display: flex;
  flex-flow: row nowrap;
  padding: 2px 0;
}

.req-content .list .list-row .label {
  flex: 0 0 150px;  
}

.req-content .list .list-row .value {
  flex: 1 1 auto;  
  overflow-wrap: anywhere;
}